import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { BlueprintNavigation } from '../../../../../../shared/components/BlueprintPagination';
import { InputGroup } from '@blueprintjs/core';
import OdinTable from '../../../../../../shared/components/OdinTable';
import { connect } from 'react-redux';
import {
  ISearchRecords,
  searchRecordsRequest,
} from '../../../../../../../core/records/store/actions';
import { displayMessage } from '../../../../../../../shared/system/messages/store/reducers';
import { getOdinSchemaByEntity } from '../../../../../../../shared/utilities/schemaHelpers';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Link } from 'react-router-dom';
import { getBrowserPath } from '../../../../../../../shared/utilities/recordHelpers';
import './styles.scss';

const { SUPPORT_MODULE } = SchemaModuleTypeEnums;
const CASE = 'Case';

interface Props {
  searchRecords: (params: ISearchRecords, cb: any) => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

interface ITableDataItem {
  key: string;
  caseNumber: string;
  contact?: string;
  source: string;
  category: string;
  SLA: JSX.Element;
  agent: JSX.Element;
  action: JSX.Element;
}

const CaseManagementTemplates: FC<Props> = (props: Props) => {
  const { searchRecords, alertMessage } = props;

  // Pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(25);
  const [totalRecordsCount, setTotalRecordsCount] = useState<number>(0);

  const [caseList, setCaseList] = useState<DbRecordEntityTransform[]>([]);
  const [searchKey, setSearchKey] = useState<string>('');
  const [caseSchema, setCaseSchema] = useState<SchemaEntity | undefined>(undefined);

  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (caseSchema) {
      getCaseList();
    }
  }, [currentPage, caseSchema]);

  useEffect(() => {
    getCaseSchema();
  }, []);

  useEffect(() => {
    if (caseSchema) {
      // getCaseList();
    }
  }, [caseSchema]);

  const getCaseSchema = async () => {
    try {
      const schema = await getOdinSchemaByEntity(SUPPORT_MODULE, CASE);
      if (schema) {
        setCaseSchema(schema);
      }
    } catch (error) {}
  };

  const getCaseList = async () => {
    if (caseSchema) {
      setIsRefreshing(true);
      searchRecords(
        {
          schema: caseSchema,
          searchQuery: {
            terms: '',
            schemas: caseSchema.id,
            sort: [{ updatedAt: { order: 'desc' } }],
            pageable: {
              page: currentPage,
              size: pageSize,
            },
          },
        },
        (response: any) => {
          const data = response?.data?.data || [];

          setIsRefreshing(false);
          setIsLoading(false);
          setTotalRecordsCount(response?.data?.pageable?.totalRecords || 0);

          setCaseList(data);
        },
      );
    }
  };

  const tableData = caseList.map((item: DbRecordEntityTransform) => {
    return {
      key: item.id,
      caseNumber: (
        <Link target="_blank" to={getBrowserPath(item)}>
          {item.recordNumber}
        </Link>
      ),
      contact: '-',
      source: '-',
      category: getProperty(item, 'Category') || '-',
      SLA: <span>SLA</span>,
      agent: (
        <Row align="middle">
          <Col span={24} className="caseAssignButton">
            <Row align="middle" justify="space-between">
              <Col>
                <i
                  className="bi bi-circle-fill"
                  style={{
                    fontSize: '0.6em',
                    color: '#008949',
                    marginTop: 10,
                    marginRight: 8,
                    verticalAlign: 'middle',
                  }}
                />
                <span>Name Lastname</span>
              </Col>
              <Col>
                <i className="bi bi-chevron-down" style={{ fontSize: '0.8em', fontWeight: 600 }} />
              </Col>
            </Row>
          </Col>
        </Row>
      ),
    };
  });

  return (
    <>
      <Row>
        <Col span={20}>
          <h1 style={{ margin: 0 }}>Cases</h1>
        </Col>
        <Col span={4} style={{ textAlign: 'right' }}>
          <InputGroup
            type="search"
            placeholder="Search Cases"
            // onChange={onSearch}
            intent={searchKey.length > 0 ? 'primary' : 'none'}
            leftIcon="search"
          />
        </Col>
      </Row>
      {/* Table */}
      <div style={{ marginTop: 30 }}>
        <OdinTable
          isLoading={isLoading}
          isRefreshing={isRefreshing}
          height="calc(100vh - 225px)"
          data={tableData}
          columns={[
            {
              key: 'caseNumber',
              title: 'Case Number',
              width: 3,
              filterable: true,
            },
            {
              key: 'contact',
              title: 'Contact',
              width: 5,
              searchable: true,
            },
            {
              key: 'source',
              title: 'Source',
              width: 3,
              filterable: true,
            },
            {
              key: 'category',
              title: 'Category',
              width: 3,
              filterable: true,
            },
            {
              key: 'SLA',
              title: 'SLA',
              width: 4,
              sortable: true,
              align: 'center',
            },
            {
              key: 'agent',
              title: 'Agent',
              width: 5,
              searchable: true,
              align: 'center',
              hideDivider: true,
            },
          ]}
        />
      </div>

      {/* Pagination */}
      <Row style={{ background: 'white' }}>
        <div style={{ padding: '10px 0' }}>
          <BlueprintNavigation
            totalCount={totalRecordsCount}
            currentPage={currentPage}
            pageSize={pageSize}
            onPaginate={setCurrentPage}
            disabled={searchKey.length > 0}
          />
        </div>
      </Row>
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  searchRecords: (params: ISearchRecords, cb: any) => dispatch(searchRecordsRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(CaseManagementTemplates);
