import { Menu, MenuItem } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import React, { useState } from 'react';
import './styles.scss';
import CaseManagementScheduling from './views/CaseManagementScheduling';
import CaseManagementTemplates from './views/CaseManagementTemplates';
import CaseManagementCaseListView from './views/CaseManagementCaseListView';
import CaseManagementDashboard from './views/CaseManagementDashboard';

interface Props {}

type TMenuItem =
  | 'Dashboard'
  | 'Teams'
  | 'Performance'
  | 'Case Load'
  | 'Cases'
  | 'Scheduling'
  | 'Employees'
  | 'Shift Templates';

const CaseManagementV2: React.FC<Props> = (props: Props) => {
  const [activeMenuItem, setActiveMenuItem] = useState<TMenuItem>('Dashboard');

  const renderContent = () => {
    switch (activeMenuItem) {
      case 'Dashboard':
        return <CaseManagementDashboard />;
      case 'Scheduling':
        return <CaseManagementScheduling />;
      case 'Shift Templates':
        return <CaseManagementTemplates />;
      case 'Cases':
        return <CaseManagementCaseListView />;
      default:
        return <></>;
    }
  };

  return (
    <Row style={{ height: 'calc(100vh - 38px)' }}>
      <Col span={3} className="CaseManagementLeftSidebar">
        <Menu>
          <MenuItem
            icon="page-layout"
            text="Dashboard"
            active={activeMenuItem === 'Dashboard'}
            onClick={() => setActiveMenuItem('Dashboard')}
          />
          <MenuItem
            icon="team"
            text="Teams"
            active={activeMenuItem === 'Teams'}
            onClick={() => setActiveMenuItem('Teams')}
          />
          <MenuItem
            className="subMenuItem"
            text="Performance"
            active={activeMenuItem === 'Performance'}
            onClick={() => setActiveMenuItem('Performance')}
          />
          <MenuItem
            className="subMenuItem"
            text="Case Load"
            active={activeMenuItem === 'Case Load'}
            onClick={() => setActiveMenuItem('Case Load')}
          />
          <MenuItem
            icon="layout-two-rows"
            text="Cases"
            active={activeMenuItem === 'Cases'}
            onClick={() => setActiveMenuItem('Cases')}
          />
          <MenuItem
            icon="timeline-events"
            text="Scheduling"
            active={activeMenuItem === 'Scheduling'}
            onClick={() => setActiveMenuItem('Scheduling')}
          />
          <MenuItem
            className="subMenuItem"
            text="Templates"
            active={activeMenuItem === 'Shift Templates'}
            onClick={() => setActiveMenuItem('Shift Templates')}
          />
          <MenuItem
            icon="person"
            text="Employees"
            active={activeMenuItem === 'Employees'}
            onClick={() => setActiveMenuItem('Employees')}
          />
        </Menu>
      </Col>
      <Col span={21} style={{ background: 'white', padding: 15 }}>
        {renderContent()}
      </Col>
    </Row>
  );
};
export default CaseManagementV2;
