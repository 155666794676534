import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { Checkbox, Spinner } from '@blueprintjs/core';
import './styles.scss';

type TColumn = {
  key: string;
  title: string;
  width: number;
  filterable?: boolean;
  searchable?: boolean;
  sortable?: boolean;
  hideDivider?: boolean;
  customElement?: JSX.Element;
  align?: 'left' | 'center' | 'right';
};

interface Props {
  data: any[];
  columns: TColumn[];
  height?: string;
  isLoading?: boolean;
  isRefreshing?: boolean;
}

const OdinTable: FC<Props> = (props: Props) => {
  const { data, columns, height, isRefreshing, isLoading } = props;
  return (
    <Row className="odinTable" style={{ opacity: isRefreshing ? 0.3 : 1 }}>
      {isLoading && (
        <Col span={24} style={{ height: height || '100%' }}>
          <Row justify="center" align="middle" style={{ height: height || '100%' }}>
            <Col>
              <Spinner />
            </Col>
          </Row>
        </Col>
      )}

      {!isLoading && (
        <Col span={24} style={{ height: '100%', width: 0, padding: 1, overflowY: 'auto' }}>
          {/* Table Header */}
          <Row
            align="middle"
            style={{
              background: '#FAFAFA',
              border: '1px solid #EDEDED',
              padding: '0px 10px',
              borderRadius: '8px 8px 0 0',
            }}
          >
            <Col span={1}>
              <Checkbox style={{ margin: 0 }} />
            </Col>
            {columns.map((column: TColumn, index: number) => (
              <Col
                key={index}
                span={column.width}
                style={{
                  height: '100%',
                  padding: '12px 2px',
                }}
              >
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    padding: '0 12px',
                    borderRight: column.hideDivider ? 'none' : '1px solid #D5D5D5',
                  }}
                >
                  {column.customElement && <Col>{column.customElement}</Col>}

                  {column.title && !column.customElement && (
                    <Col
                      span={column.align ? 20 : undefined}
                      style={{ textAlign: column.align || 'left' }}
                    >
                      <span style={{ fontWeight: 600 }}>{column.title}</span>
                    </Col>
                  )}

                  {column.filterable && (
                    <Col>
                      <i className="bi bi-funnel" style={{ color: '#646464' }} />
                    </Col>
                  )}

                  {column.searchable && (
                    <Col>
                      <i className="bi bi-search" style={{ color: '#646464' }} />
                    </Col>
                  )}

                  {column.sortable && (
                    <Col>
                      <i className="bi bi-sort-down" style={{ color: '#646464' }} />
                    </Col>
                  )}
                </Row>
              </Col>
            ))}
          </Row>
          {/*  Table Body */}
          <Row
            align="middle"
            style={{
              background: 'white',
              overflowY: 'auto',
              border: '1px solid #EDEDED',
              borderRadius: '0 0 8px 8px',
              height: height || '100%',
            }}
          >
            {/* Map Data as Col */}
            {data.map((datarow: any, index: number) => {
              return (
                <Col span={24} key={`Col1${datarow.key}`}>
                  <Row
                    className="dataRow"
                    align="middle"
                    key={`Row2${datarow.key}`}
                    style={{
                      borderBottom: data.length === index + 1 ? 'none' : '1px solid #F2F2F2',
                    }}
                  >
                    <Col span={1}>
                      <Checkbox style={{ margin: 0 }} />
                    </Col>
                    {/* Map Col for each data object item, use column span from props.columns */}
                    {Object.keys(datarow).map((key: string, index: number) => {
                      if (key !== 'key') {
                        return (
                          <Col
                            key={`Col2${key}`}
                            style={{ textAlign: columns[index - 1]?.align || 'left' }}
                            span={columns[index - 1]?.width}
                          >
                            {datarow[key]}
                          </Col>
                        );
                      } else {
                        return <></>;
                      }
                    })}
                  </Row>
                </Col>
              );
            })}
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default OdinTable;
