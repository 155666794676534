import { Button } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { MyCasesContext } from '../../..';
import { getBrowserPath } from '../../../../../../../../shared/utilities/recordHelpers';
import './styles.scss';
import { MY_CASES_SET_REPLIED_EMAIL } from '../../../store/constants';

dayjs.extend(relativeTime);

interface Props {
  email: DbRecordEntityTransform;
}

const MyCasesEmail: React.FC<Props> = (props: Props) => {
  const { email } = props;
  const { state, dispatch } = useContext(MyCasesContext);

  const { selectedCase } = state;

  return (
    <Row justify="end" style={{ padding: 15 }}>
      <Col span={24} className="myCasesUsersEmail">
        <Row>
          {/* Email Title & Toolbar */}
          <Col span={12}>
            <i className="bi bi-envelope" style={{ marginRight: 5 }} />
            <span style={{ fontWeight: 500 }}>Email</span>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Link to={getBrowserPath(email)} target="_blank">
              <Button small minimal icon={<i className="bi bi-box-arrow-up-right" />} />
            </Link>
            <Button
              disabled={!!state.repliedEmail}
              small
              minimal
              icon={<i className="bi bi-reply" style={{ fontSize: '1.2em', color: '#5F6B7C' }} />}
              onClick={() => {
                dispatch({ type: MY_CASES_SET_REPLIED_EMAIL, payload: email });
              }}
            />
          </Col>

          {/* From and Timestamp */}
          <Col span={16} style={{ marginTop: 5 }}>
            <span style={{ fontSize: '0.8em', fontWeight: 600 }}>{getProperty(email, 'From')}</span>
          </Col>
          <Col span={8} style={{ textAlign: 'right', marginTop: 5 }}>
            <span style={{ fontSize: '0.8em', color: '#545A5D' }}>
              {dayjs(email.createdAt).format('ddd, DD MMM')} ({dayjs(email.createdAt).fromNow()})
            </span>
          </Col>
          <Col span={16}>
            <span style={{ fontSize: '0.8em', color: '#545A5D' }}>
              To: {getProperty(email, 'To')}
            </span>
            {getProperty(email, 'Cc') && (
              <span style={{ fontSize: '0.8em', color: '#545A5D' }}>
                , Cc: {getProperty(email, 'Cc')}
              </span>
            )}
          </Col>

          {/* User / Timestamp */}
          <Col span={24} style={{ marginTop: 10 }}>
            <Col span={24}>
              <span style={{ fontWeight: 600 }}>{getProperty(email, 'Subject')}</span>
            </Col>
            <Col span={24} style={{ marginTop: 5 }}>
              <span>{getProperty(email, 'Message')}</span>
            </Col>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default MyCasesEmail;
