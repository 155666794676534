import { Button, InputGroup, NonIdealState } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { MyCasesContext } from '../..';
import { httpPost } from '../../../../../../../shared/http/requests';
import {
  MY_CASES_SET_FEED_BOTTOM_REF,
  MY_CASES_SET_FEED_CONTAINER_REF,
} from '../../store/constants';
import MyCasesEmailEditorWidget from '../MyCasesEmailEditorWidget';
import MyCasesNoteEditorWidget from '../MyCasesNoteEditorWidget';
import MyCasesEmail from './MyCasesEmail';
import MyCasesMessage from './MyCasesMessage';
import MyCasesNote from './MyCasesNote';
import './styles.scss';

const { SUPPORT_MODULE } = SchemaModuleTypeEnums;
const { NOTE } = SchemaModuleEntityTypeEnums;

interface Props {
  userReducer: any;
}

const MyCasesConversationFeed: React.FC<Props> = (props: Props) => {
  const { userReducer } = props;
  const { state, dispatch } = useContext(MyCasesContext);
  const selectedCase: DbRecordEntityTransform | undefined = state.selectedCase;
  const notes: DbRecordEntityTransform[] = state.selectedCaseNotes;
  const messages: DbRecordEntityTransform[] = state.selectedCaseMessages;
  const emails: DbRecordEntityTransform[] = state.selectedCaseEmails;

  const [messageText, setMessageText] = useState<string>('');
  const [isSendingMessage, setIsSendingMessage] = useState<boolean>(false);

  const feedContainerRef = useRef<HTMLDivElement | null>(null);
  const feedBottomRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (feedBottomRef.current) {
      dispatch({ type: MY_CASES_SET_FEED_BOTTOM_REF, payload: feedBottomRef });
    }
  }, [feedBottomRef]);

  useEffect(() => {
    if (feedContainerRef.current) {
      dispatch({ type: MY_CASES_SET_FEED_CONTAINER_REF, payload: feedContainerRef });
    }
  }, [feedContainerRef]);

  // We need to merge the conversations and messages into a single feed, and sort by createdBy date
  let Feed: DbRecordEntityTransform[] = [];
  if (messages.length > 0) {
    Feed = Feed.concat(messages);
  }
  if (notes && notes.length > 0) {
    Feed = Feed.concat(notes);
  }
  if (emails && emails.length > 0) {
    Feed = Feed.concat(emails);
  }
  Feed.sort((a, b) => {
    return dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf();
  });

  const isRecordMessage = (record: DbRecordEntityTransform) => {
    return record.entity && record.entity.indexOf('NotificationModule:Message') > -1;
  };

  const isRecordNote = (record: DbRecordEntityTransform) => {
    return record.entity && record.entity.indexOf('SupportModule:Note') > -1;
  };

  const isRecordEmail = (record: DbRecordEntityTransform) => {
    return record.entity && record.entity.indexOf('NotificationModule:Email') > -1;
  };

  const smoothScrollFeedToBottom = () => {
    const feedContainer = state.feedContainerRef?.current;
    if (feedContainer) {
      feedContainer.scrollTo({
        top: feedContainer.scrollTop + feedContainer.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  const sendChatMessage = async () => {
    if (messageText.length && state.selectedCaseConversation) {
      setIsSendingMessage(true);
      try {
        const res = await httpPost(
          `ChatModule/v1.0/gateway/conversation/${state.selectedCaseConversation.id}/message/send`,
          {
            from: userReducer.user.email,
            message: messageText,
          },
        );

        console.log('Message response!', res);

        setIsSendingMessage(false);
        setMessageText('');
      } catch (error) {
        setIsSendingMessage(false);
      }
    }
  };

  const doesSelectedContactHaveEmail = () => {
    return !!getProperty(state.selectedCaseContact, 'EmailAddress');
  };

  const caseIsSolvedOrClosed = () => {
    return (
      state.selectedCase?.stage?.name === 'Closed' || state.selectedCase?.stage?.name === 'Solved'
    );
  };

  return (
    <Col
      span={state.isContactPanelOpen ? 23 : 17}
      style={{
        background: 'white',
        height: 'calc(100vh - 180px)',
        borderLeft: '1px solid #D9DADA',
      }}
    >
      <Row style={{ backgroundColor: 'white', height: 'calc(100vh - 240px)' }}>
        {/* No Selected Case */}
        {!state.selectedCase && (
          <Col span={24} style={{ textAlign: 'center', padding: 20 }}>
            <NonIdealState
              icon="chat"
              title="Select a Case"
              description="Select a case from the list to view the conversation"
            />
          </Col>
        )}

        {/* Selected Case - Show Feed */}

        <Col
          ref={feedContainerRef}
          span={24}
          style={{
            overflowY: 'auto',
            height: '100%',
            alignContent: 'flex-end',
            display: !state.selectedCase ? 'none' : 'block',
          }}
        >
          {Feed.map((item: DbRecordEntityTransform, i: number) => {
            if (isRecordMessage(item)) {
              return <MyCasesMessage message={item} />;
            } else if (isRecordNote(item)) {
              return <MyCasesNote note={item} />;
            } else if (isRecordEmail(item)) {
              return <MyCasesEmail email={item} />;
            } else return <></>;
          })}

          {selectedCase && (
            <Row style={{ padding: 15, marginRight: 10 }} justify="end">
              <Col>
                {/* Email Customer Button */}
                {state.selectedCaseContact && doesSelectedContactHaveEmail() && (
                  <MyCasesEmailEditorWidget />
                )}
                {/* Add Internal Comment */}
                <MyCasesNoteEditorWidget />
              </Col>
            </Row>
          )}

          {/* Feed bottom */}
          <div id="bottomRef" ref={feedBottomRef} />

          {state.isScrollDownButtonVisible && (
            <Row style={{ bottom: 15, left: 15, position: 'sticky', paddingRight: 20 }}>
              <Col span={24} style={{ textAlign: 'right' }}>
                <Button
                  icon="double-chevron-down"
                  onClick={smoothScrollFeedToBottom}
                  outlined
                  style={{
                    borderRadius: 50,
                    background: 'white',
                  }}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      <Row style={{ borderTop: '1px solid #D9DADA' }} align="middle">
        <Col span={24}>
          <Row style={{ margin: 10 }}>
            <Col sm={18} xl={19} xxl={20}>
              <InputGroup
                style={{ border: 'none', boxShadow: 'none' }}
                large
                type="text"
                placeholder="Chat text"
                value={messageText}
                disabled={!state.selectedCase || isSendingMessage || caseIsSolvedOrClosed()}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setMessageText(e.target.value)
                }
                // If enter key is pressed, send the message
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    sendChatMessage();
                  }
                }}
              />
            </Col>
            <Col sm={6} xl={5} xxl={4} style={{ textAlign: 'right' }}>
              <Button
                icon="paperclip"
                disabled
                minimal
                large
                style={{ marginRight: 5, borderRadius: 5 }}
              />
              <Button
                intent="primary"
                disabled={!messageText.length}
                text="Send"
                loading={isSendingMessage}
                onClick={sendChatMessage}
                large
                style={{ borderRadius: 5 }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(MyCasesConversationFeed);
