import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../core/navigation/ProtectedRoute';
import DetailView from '../../core/records/components/DetailViewContext';
import RecordListView from '../../core/records/components/ListView';
import AgentCaseDashboard from '../../v2/modules/SupportModule/views/AgentCaseDashboard';
import CaseDetailView from '../../v2/modules/SupportModule/views/CaseDetailView';
import CaseManagementV2 from '../../v2/modules/SupportModule/views/CaseManagementV2';
import LinearIssues from '../../v2/modules/SupportModule/views/LinearIssues';
import MyCasesView from '../../v2/modules/SupportModule/views/MyCasesView';
import MyCasesViewV2 from '../../v2/modules/SupportModule/views/MyCasesViewV2';
import DefaultDetailView from '../../v2/shared/views/DetailView';
import HtmlRecordDetailViewWithSlate from '../DefaultViews/KnowledgeArticleRecordDetailView';
import HtmlContentViewWithSlate from '../DefaultViews/KnowledgeArticleView';

const { SUPPORT_MODULE } = SchemaModuleTypeEnums;

export const SupportModuleRoutes = () => {
  return (
    <Switch>
      <ProtectedRoute
        exact
        path={`/${SUPPORT_MODULE}/LinearIssue`}
        moduleName={SUPPORT_MODULE}
        entityName="LinearIssue"
        component={<LinearIssues />}
      />

      {/* CASE MANAGEMENT V2 */}
      <ProtectedRoute
        exact
        path={`/${SUPPORT_MODULE}/CaseManagementV2`}
        moduleName={SUPPORT_MODULE}
        entityName="MyCases"
        component={<CaseManagementV2 />}
      />

      {/* CASES V1 */}
      <ProtectedRoute
        exact
        path={`/${SUPPORT_MODULE}/MyCases`}
        moduleName={SUPPORT_MODULE}
        entityName="MyCases"
        component={<MyCasesView />}
      />

      {/* CASES V2 */}
      <ProtectedRoute
        exact
        path={`/${SUPPORT_MODULE}/MyCasesV2`}
        moduleName={SUPPORT_MODULE}
        entityName="MyCases"
        component={<MyCasesViewV2 />}
      />

      <ProtectedRoute
        exact
        path={`/${SUPPORT_MODULE}/KnowledgeArticle/:recordId`}
        moduleName={SUPPORT_MODULE}
        entityName="KnowledgeArticle"
        component={
          <DetailView moduleName={SUPPORT_MODULE} entityName="KnowledgeArticle">
            <HtmlRecordDetailViewWithSlate />
          </DetailView>
        }
      />
      <ProtectedRoute
        exact
        path={`/${SUPPORT_MODULE}/KnowledgeArticle/:recordId/view`}
        moduleName={SUPPORT_MODULE}
        entityName="KnowledgeArticle"
        component={
          <DetailView moduleName={SUPPORT_MODULE} entityName="KnowledgeArticle">
            <HtmlContentViewWithSlate />
          </DetailView>
        }
      />

      <ProtectedRoute
        exact
        path={`/${SUPPORT_MODULE}/CaseQueueListView`}
        moduleName={SUPPORT_MODULE}
        entityName="CaseQueueListView"
        component={<AgentCaseDashboard />}
      />

      <ProtectedRoute
        exact
        path={`/${SUPPORT_MODULE}/Case/:recordId`}
        moduleName={SUPPORT_MODULE}
        entityName="Case"
        component={
          <DetailView moduleName={SUPPORT_MODULE} entityName="Case">
            <CaseDetailView />
          </DetailView>
        }
      />
      <ProtectedRoute
        exact
        path={`/${SUPPORT_MODULE}/:entityName`}
        moduleName={SUPPORT_MODULE}
        component={<RecordListView moduleName={SUPPORT_MODULE} />}
      />

      <ProtectedRoute
        exact
        path={`/${SUPPORT_MODULE}/:entityName/:recordId`}
        moduleName={SUPPORT_MODULE}
        component={
          <DetailView moduleName={SUPPORT_MODULE}>
            <DefaultDetailView />
          </DetailView>
        }
      />
    </Switch>
  );
};
